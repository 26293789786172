.package-info {
  display: flex;
  align-items: flex-start;
  background-color: #EB5B1E33;
  border-radius: 8px;
  font-family: Open Sans;
  max-width: 400px;
}

.icon {
  padding: 12px 12px 12px 16px  ;
  font-size: 24px;
  margin-right: 10px;
  color: #e0581d;
}

.text {
  margin-top: 5px;
  font-size: 14px;
}

.title {
  color: #e0581d;
  font-weight: bold;
}

.info {
  color: #333;
}

.number {
  font-weight: bold;

}